/* eslint-disable max-len */
import React from "react";
import PropTypes from "prop-types";

import { Image } from "@sparefoot/react-commons";
import { MapPinOutline } from "components/core/Icons";
import { H5, Text, Paragraph } from "components/core/Typography";

import "./TestimonialCard.scss";

const TestimonialCard = ({ title, body, author, location, imageSrc }) => (
	<div className="testimonial-card">
		<div className="card-image">
			<Image
				src={imageSrc}
				alt={author}
				lazy
				width="152"
				height="152"
			/>
		</div>
		<div className="card-body">
			<div className="card-header">
				<H5>{title}</H5>
				<div>
					<Text className="author-text">{`by ${author}`}</Text>|
					<Text className="location-text">
						<MapPinOutline fill="lightGray" />
						{location}
					</Text>
				</div>
			</div>
			<Paragraph
				size="small"
				className="quote-text"
			>{`${body}”`}</Paragraph>
		</div>
	</div>
);

TestimonialCard.propTypes = {
	body: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	author: PropTypes.string.isRequired,
	location: PropTypes.string.isRequired,
	imageSrc: PropTypes.string.isRequired
};

export default TestimonialCard;
